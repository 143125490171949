<script>
    // import Swal from "sweetalert2";
    import axios from 'axios';
    import utilities from './../mixins/utilities';
    import authUtilities from './../mixins/auth-utilities';

    export default {
        mixins: [ utilities, authUtilities ],
        data() {
            return {
                messages: []
            }
        },
        methods: {
            getNotifications() {
                if(this.isLoggedIn()) {
                    // TODO: FIXEN
                    let self = this;

                    axios.get('/api/notify/latest').then((data) => {
                        // self.messages = JSON.parse(JSON.stringify(data.data));
                        let notifications = data.data;

                        if(notifications.length > 0) {
                            notifications.forEach(row => {
                                this.messages.push({
                                    id: row.id,
                                    title: row.title,
                                    content: row.message
                                });
                                if(row.isAutoClose) {
                                    setTimeout(function() {
                                        self.closeNotification(row.id);
                                    }, 5000);
                                }
                            });
                        }
                    }).catch((er) => {
                        this.showApiError(er);
                    });
                }
            },
            closeNotification(index) {
                let self = this;
                if(this.messages.length > 0) {
                    this.messages.forEach(function(row, rowIndex) {
                        if(row.id == index) {
                            self.messages.splice(rowIndex, 1);
                        }
                    });
                }
            }
        },
        created() {
            let self = this;
            this.getNotifications();
            clearInterval(window.notificationsInterval);
            window.notificationsInterval = setInterval(function() {
                self.getNotifications();
            }, 10000);
        }
    }
</script>

<template>
    <button class="btn btn-primary notification-button" @click.prevent="getNotifications()">Trigger notificaties</button>
    <div class="toast-container position-fixed top-0 end-0 p-3" style="z-index: 1500">
        <div id="liveToast" class="toast toast-border-success show" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false" v-for="toast in messages" :key="toast.id">
            <div class="toast-header">
                <strong class="me-auto">{{ toast.title }}</strong>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
                <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-2">
                        <i class="ri-checkbox-circle-fill align-middle"></i>
                    </div>
                    <div class="flex-grow-1">
                        <h6 class="mb-0" style="font-weight: 400;" v-html="toast.content"></h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .toast.toast {
        background-color: #FFF;
    }

    .notification-button {
        position: fixed;
        z-index: 50;
        bottom: 20px;
        right: 20px;
    }
</style>