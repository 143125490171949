<script>
export default {
    methods: {
        getUser() {
            let user = JSON.parse(localStorage.getItem('user'));
            return user;
        },
        getUserToken() {
            return localStorage.getItem('jwt');
        },
        getUserRole() {
            return this.getUser().role;
        },
        getUserAppRoles() {
            return this.getUser().app_roles;
        },
        isAdmin() {
            return this.getUserAppRoles().includes('ROLE_ADMIN');
        },
        isContact() {
            return this.getUserAppRoles().includes('ROLE_CONTACT');
        },
        isUser() {
            return this.getUserAppRoles().includes('ROLE_USER');
        },
        isUberAdmin() {
            return this.getUserAppRoles().includes('ROLE_UBER_ADMIN');
        },
        isLoggedIn() {
            return (
                this.getUser() != null &&
                typeof this.getUser().role !== 'undefined'
            );
        },
        getUserIri() {
            return '/api/users/' + this.getUser()._id;
        },
        getUserId() {
            return this.getUser()._id;
        },
        getConfig(key = null) {
            let config = JSON.parse(localStorage.getItem('config'));
            if (key) {
                if (typeof config[key] !== 'undefined') {
                    return config[key];
                } else {
                    return null;
                }
            } else {
                return config;
            }
            // return this.getUser().config
        },
        getUserCompany() {
            return this.getUser().company;
        },
        logOut() {
            localStorage.removeItem('user');
            this.$router.push({ name: 'login' });
        },
    },
};
</script>
