<template>
    <router-view></router-view>
    <toasts v-if="this.isLoggedIn()"></toasts>
</template>

<script>
import toasts from './components/toasts.vue';
import authUtilities from '@/mixins/auth-utilities';

export default {
    mixins: [authUtilities],
    name: 'App',
    components: {
        toasts
    }
};
</script>
