<script>
// import Swal from "sweetalert2";

export default {
    data() {
        return {
            baseUrl: process.env.VUE_APP_AXIOS_API_URL
        };
    },
    methods: {
        formatValue(value, type) {
            switch (type) {
                case 'dateTime':
                    return this.formatDateTime(value);
                case 'date':
                    return this.formatDate(value);
                case 'price':
                    return this.formatPrice(value);
                case 'boolean':
                    return value ? 'Ja' : 'Nee';
                default:
                    return value;
            }
        },
        formatDateTime(dateTime) {
            if (typeof dateTime === 'undefined' || dateTime == '') {
                return '';
            }
            return new Date(dateTime).toLocaleString('nl-nl');
        },
        formatDate(date) {
            if (typeof date === 'undefined' || date == '') {
                return '';
            }
            return new Date(date).toLocaleDateString('nl-nl');
        },
        formatPrice(price, symbol = true) {
            let formatter = '';
            if (symbol) {
                formatter = new Intl.NumberFormat('nl-NL', {
                    style: 'currency',
                    currency: 'EUR'
                });
            } else {
                formatter = new Intl.NumberFormat('en-US');
            }

            return formatter.format(price);
        },
        formatPercentage(number) {
            return `${number.toFixed(2)}%`;
        },
        getUuidFromIri(iri) {
            if (typeof iri === 'string') {
                return iri.split('/')[3];
            }
            return '';
        },
        setValueFromEntitySelect(value, varName) {
            eval(`this.${varName} = "${value}"`);
        },
        pad(n) {
            return n < 10 ? `0${n}` : n;
        },
        showApiError(er) {
            console.log(er);
            // Swal.fire({
            //     title: er,
            //     html: (er.response.data['hydra:description'] ? er.response.data['hydra:description'] : '') + ' <br><br><strong>URL: ' + er.response.config.url + '</strong>',
            //     icon: 'error',
            //     confirmButtonText: 'Oké'
            // });
        },
        diff(obj1, obj2) {
            const result = {};
            for (const [key] of Object.entries(obj1)) {
                if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) result[key] = obj1[key];
            }
            return result;
        },
        transformLineItemsData(lineItems) {
            lineItems.forEach((lineItem) => {
                lineItem.discountUnitPrice = String(lineItem.discountUnitPrice);
                lineItem.setupCost = String(lineItem.setupCost).replace(/,/, '.');
            });

            return lineItems;
        },
        getIsSendReviewValue(value) {
            switch (value) {
                case 1:
                    return 'Te beoordelen';
                case 2:
                    return 'Ja';
                case 3:
                    return 'Nee';
                default:
                    return null;
            }
        },
        copyToClipboard(text) {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    }
};
</script>
